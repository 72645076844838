<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="480" height="480" viewBox="0 0 480 480" xml:space="preserve">
<desc>Created with Fabric.js 3.6.3</desc>
<defs>
</defs>
<g transform="matrix(17.75 0 0 17.75 240.28 240.27)" id="Ebene_1"  >
<path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(21,22,22); fill-rule: nonzero; opacity: 1;"  transform=" translate(-239.4, -421.35)" d="M 248.7 431 h -7.8 v -1.5 h 4.5 v -16.3 h -4.5 v -1.5 h 7.8 V 431 z M 236.4 416.3 h 6.1 v 10.1 h -6.1 V 416.3 z M 238 413.2 h -4.5 v 16.3 h 4.5 v 1.5 h -7.8 v -19.3 h 7.8 V 413.2 z M 251.9 408.4 h -25 v 25.9 h 25 V 408.4 z" stroke-linecap="round" />
</g>
</svg>
</template>

<script setup lang="ts">
</script>

<style scoped>
</style>
