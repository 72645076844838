import { LocaleMessages, VueMessageType } from 'vue-i18n'

export const appLocales: LocaleMessages<VueMessageType> = {
  en: {
    appBarElement: {
      appTitle: 'IIoT Client',
      settings: {
        language: 'Language'
      }
    },
    connectionElement: {
      title: 'Connection',
      connect: 'Connect',
      username: 'User',
      password: 'Password'
    },
    statusInfoElement: {
      connectionStates: {
        connecting: 'Connecting to MQTT broker',
        subscribing: 'Subscribing to Communication Electronic\'s Data Stream',
        dialog: 'No connection to the broker could be established. The corresponding port (8084) is possibly blocked by your firewall. Please contact your system administrator.',
        back: 'Back to Login Page'
      },
      data: {
        dataKeys: {
          deviceConnection: 'Connection',
          powerElectronicsReady: 'Power Electronics',
          epsReady: 'Peak Load Reduction',
          mainsPowerSupply: 'Mains Power Supply',
          uz: 'Z',
          uc: 'C'
        },
        dataValues: {
          mainsPowerSupply: {
            true: 'active',
            false: 'failure'
          },
          else: {
            true: 'active',
            false: 'inactive'
          },
          client: 'Client',
          broker: 'Broker'
        }
      }
    }
  },
  de: {
    appBarElement: {
      appTitle: '-IIoT-Client',
      settings: {
        language: 'Sprache'
      }
    },
    connectionElement: {
      title: 'Verbindungsaufbau',
      connect: 'Verbinden',
      username: 'Benutzer',
      password: 'Passwort'
    },
    statusInfoElement: {
      connectionStates: {
        connecting: 'Verbindung mit MQTT-Broker wird hergestellt',
        subscribing: 'Abonniere Geräte-Daten-Stream',
        dialog: 'Es konnte keine Verbindung zum Broker hergestellt werden. Möglicherweise wird der entsprechende Port (8084) von Ihrer Firewall blockiert. Bitte wenden Sie sich an Ihren Systemadministrator.',
        back: 'Zurück zur Login-Seite'
      },
      data: {
        dataKeys: {
          deviceConnection: 'Verbindung',
          powerElectronicsReady: 'Leistungselektronik',
          epsReady: 'Lastspitzenreduktion',
          mainsPowerSupply: 'Netzstromversorgung',
          uz: 'Z',
          uc: 'C'
        },
        dataValues: {
          mainsPowerSupply: {
            true: 'aktiv',
            false: 'Ausfall'
          },
          else: {
            true: 'aktiv',
            false: 'inaktiv'
          },
          client: 'Client',
          broker: 'Broker'
        }
      }
    }
  }
}
