import { createStore } from 'vuex'

export default createStore({
  state: {
    connectionCredentials: {
      host: 'broker.emqx.io',
      port: 8084,
      path: 'mqtt',
      protocol: 'wss',
      username: '',
      password: '',
      connectionType: 'public'
    },
    connectionStates: {
      deviceConnection: false,
      brokerConnection: false,
      topicSubscription: false,
      initialConnect: false,
      queueTimeout: false
    },
    systemData: {
      powerElectronicsReady: false,
      epsReady: false,
      mainsPowerSupply: true,
      uz: '-',
      uc: '-'
    }
  },
  getters: {
    connectURL (state) {
      return `${state.connectionCredentials.protocol}://${state.connectionCredentials.host}:${state.connectionCredentials.port}/${state.connectionCredentials.path}`
    },
    clientId () {
      return `koch_customer_${Math.random().toString(16).slice(3)}`
    }
  },
  mutations: {
    updateConnectionState (state, payload) {
      state.connectionStates[payload.type as keyof boolean] = payload.value
    },
    updateSystemData (state, payload) {
      state.systemData[payload.type as keyof (string | number | boolean)] = payload.value
    },
    resetStates (state) {
      state.systemData = {
        powerElectronicsReady: false,
        epsReady: false,
        mainsPowerSupply: true,
        uz: '-',
        uc: '-'
      }
      state.connectionStates = {
        deviceConnection: false,
        brokerConnection: false,
        topicSubscription: false,
        initialConnect: false,
        queueTimeout: false
      }
    },
    toggleQueueTimeout (state) {
      state.connectionStates.queueTimeout = !state.connectionStates.queueTimeout
    }
  },
  actions: {
  },
  modules: {
  }
})
