<template>
    <svg width="100%" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="265 408 105 27" style="enable-background:new 0 0 595.3 841.9;" xml:space="preserve">
        <polygon class="st0" points="345.2,434.3 352.7,434.3 352.7,423.9 361.2,423.9 361.2,434.3 368.7,434.3 368.7,408.5 361.2,408.5 361.2,417.4 352.7,417.4 352.7,408.5 345.2,408.5 "/>
        <path class="st0" d="M333.8,419.3h8.5c-0.3-7.4-4.7-11.5-12.8-11.5c-8.6,0-13,4.6-13,13.6c0,8.9,4.4,13.6,13,13.6
            c7.7,0,12-3.8,12.8-10.8h-8.6c-0.5,2.8-1.9,4.2-4.2,4.2c-3.2,0-4.9-2.4-4.9-6.9c0-4.5,1.7-7,4.9-7
            C332.1,414.4,333.6,416.1,333.8,419.3"/>
        <path class="st0" d="M288.7,421.4c0,8.9,4.4,13.6,13,13.6c8.6,0,13-4.7,13-13.6c0-8.9-4.4-13.6-13-13.6 C293.1,407.8,288.7,412.5,288.7,421.4 M296.8,421.4c0-4.5,1.7-7,4.9-7c3.2,0,4.9,2.5,4.9,7c0,4.5-1.7,6.9-4.9,6.9 C298.5,428.3,296.8,425.9,296.8,421.4"/>
        <polygon class="st0" points="264.3,434.3 271.7,434.3 271.7,427.4 275,423.8 280.2,434.3 289.5,434.3 280.7,417.8 288.7,408.5 278.6,408.5 271.7,417.4 271.7,408.5 264.3,408.5 "/>
        <path class="st1" d="M248.7,431h-7.8v-1.5h4.5v-16.3h-4.5v-1.5h7.8V431z M236.4,416.3h6.1v10.1h-6.1V416.3z M238,413.2h-4.5v16.3 h4.5v1.5h-7.8v-19.3h7.8V413.2z M251.9,408.4h-25v25.9h25V408.4z"/>
    </svg>
</template>

<script setup lang="ts">
</script>

<style scoped>
        .st0{fill:#E30018;}
        .st1{fill:#151616;}
</style>
